<!--  -->
<template>
    <div class="TopRemak">
        <header>
            <div class="leftbox">
                <div class="iconbox">
                    <span :class="topmarkObj.font"></span>
                </div>
                <div class="content">{{ topmarkObj.topmark }} </div>
            </div>
            <div class="English"> {{ topmarkObj.English }}</div>
        </header>
    </div>
</template>

<script>
export default {
    name: "TopRemak",
    components: {},
    props: ['topmarkObj'],
    data() {
        return {

        };
    },
    methods: {

    },
    mounted() {

    },
}
</script>
<style lang='scss' scoped>
header {
    width: 100%;
    height: 50px;
    color: white;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $theme_color1;
    padding-right: 60px;
}

.English {
    font-family: 'JinBuTi';
    font-size: 28px;
    font-weight: bold;
    margin-right: 10px;
    color: $theme_color1;
}

.leftbox {
    font-family: 'JinBuTi';
    display: flex;
    font-size: 20px;
    align-items: center;
    background-color: $theme_color1;
    height: 100%;
    padding-left: 40px;
    padding-right: 100px;
    // 0 表示从这个位置（也就是 60 像素之后，因为前面的透明占了 60 像素）开始一直到渐变结束（通常是元素的对应边界）
    background: linear-gradient(-125deg, transparent 20px, $theme_color1);
}

.iconbox {
    margin-right: 10px;
    .icon-qiye {
        font-size: 28px;
        font-weight: bold;
    }

    .el-icon-s-promotion {
        font-size: 28px;
        font-weight: bold;
    }

    .icon-a-14-chengyuanguanli {
        font-size: 28px;
        font-weight: bold;
    }
}
</style>