
import VueRouter from 'vue-router'
import routes from './routes'
//重写push方法
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	// 如果调用的时候传入onResolve和onReject时执行
	if (onResolve && onReject) return originalPush.call(this, location, onResolve, onReject);
	// 否则直接用catch捕获Reject返回的错误并返回
	return originalPush.call(this, location).catch(err => err);
}

//重写replace方法
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
	// 如果调用的时候传入onResolve和onReject时执行
	if (onResolve && onReject) return originalReplace.call(this, location, onResolve, onReject);
	// 否则直接用catch捕获Reject返回的错误并返回
	return originalReplace.call(this, location).catch(err => err);
}


//对外暴露一个路由器,实质是VueRouter类的实例,一个路由器可以管理多个路由
const router = new VueRouter({
	mode: 'history',
	routes
})

//全局前置路由守卫————初始化的时候被调用、每次路由切换之前被调用
router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0)
	// console.log('前置路由守卫',to,from)
	if (to.meta.isAuth) { //判断是否需要鉴权
		if (localStorage.getItem('name') === 'huifengxian') {
			next()
		} else {
			alert('无权限查看！')
		}
	} else {
		next()
	}
})

//全局后置路由守卫————初始化的时候被调用、每次路由切换之后被调用
router.afterEach((to, from) => {
	// console.log('后置路由守卫',to,from)
	document.title = to.meta.title || '知识产权行业软件'
})

//暴露VueRouter类的实例
export default router