
import {commonData} from './mixin'
import NameCard from './components/NameCard'
import Adbox from './components/Adbox'
import Asides from '@/components/Asides'
import TopRemak from '@/components/TopRemak'
export default {
    install(Vue) {
        // 1. 添加全局过滤器
        Vue.filter()

        // 2. 添加全局指令
        Vue.directive()

        // 3. 配置全局混入(合)
        Vue.mixin(commonData)
        
        // 注册全局组件 Adbox.name是引入模块的中的name
        Vue.component(Adbox.name, Adbox);
        Vue.component(NameCard.name, NameCard);
        Vue.component(Asides.name, Asides);
        Vue.component(TopRemak.name, TopRemak);
        // 4. 添加实例方法和属性
        Vue.prototype.$myMethod = function () { }
        Vue.prototype.$myProperty = 'xxxx'
    }
}
