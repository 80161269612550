export default [
    {
        name: 'Patent',
        path: 'Patent',
        component: () => import('@/pages/Api/Patent'),
        meta: { title: '专利分析数据' }
    },
    {
        name: 'Trademark',
        path: 'Trademark',
        component: () => import('@/pages/Api/Trademark'),
        meta: { title: '商标检索数据' }
    }
]