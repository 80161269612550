export default [
    {
        name: 'IndustryTrends',
        path: 'IndustryTrends',
        component: () => import('@/pages/Aticle/IndustryTrends'),
        meta: { title: '行业动态' },
        children: [
            {
                name: 'NewsDetail-1',
                path: 'NewsDetail',
                component: () => import('@/pages/Aticle/NewsDetail'),
                meta: { title: '文章详情' },
                // props值为函数，该函数返回的对象中每一组key-value都会通过props传给Detail组件
                props(route) {
                    return {
                        id: route.query.id
                    }
                }
            }
        ]
    },
    {
        name: 'SoftwareUpdate',
        path: 'SoftwareUpdate',
        component: () => import('@/pages/Aticle/SoftwareUpdate'),
        meta: { title: '软件更新' },
        children: [
            {
                name: 'NewsDetail-2',
                path: 'NewsDetail',
                component: () => import('@/pages/Aticle/NewsDetail'),
                meta: { title: '文章详情' },
                // props值为函数，该函数返回的对象中每一组key-value都会通过props传给Detail组件
                props(route) {
                    return {
                        id: route.query.id
                    }
                }
            }
        ]
    },
    {
        name: 'AllNews',
        path: 'AllNews',
        component: () => import('@/pages/Aticle/AllNews'),
        meta: { title: '全部' },
        children: [
            {
                name: 'NewsDetail-3',
                path: 'NewsDetail',
                component: () => import('@/pages/Aticle/NewsDetail'),
                meta: { title: '文章详情' },
                // props值为函数，该函数返回的对象中每一组key-value都会通过props传给Detail组件
                props(route) {
                    return {
                        id: route.query.id
                    }
                }
            }
        ]
    },
]