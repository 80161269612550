<!--  -->
<template>
    <div class="NameCard">
        <div class="cardbox">
            <div class="nameCard" v-for="item in nameCards">
                <div class="letfbox">
                    <div class="logobox">
                        <img :src="ossUrl + item.logourl" alt="">
                    </div>
                    <div class="codebox">
                        <img :src="ossUrl + item.codeurl" alt="">
                    </div>
                </div>
                <div class="line"></div>
                <div class="rightbox">
                    <div class="namebox">
                        <span class="name">{{ item.contact }}</span>
                        <span class="slash"> / </span>
                        <span>{{ item.post }} </span>
                        <span class="mobile">{{ item.mobile }}</span>
                    </div>
                    <div class="cnamebox">{{ item.name }}</div>
                    <div class="cardinfo">
                        <span class="iconfont icon-dianhua1"></span>
                        <span>{{ item.phone }}</span>
                    </div>
                    <div class="cardinfo">
                        <span class="iconfont icon-youxiang"></span>
                        <span>{{ item.email }}</span>
                    </div>
                    <div class="cardinfo">
                        <span class="iconfont icon-wangzhi">
                        </span><span>{{ item.http }}</span>
                    </div>
                    <div class="cardinfo address">
                        <span class="iconfont icon-dizhiguanli"></span>
                        <span>{{ item.address }}</span>
                    </div>
                </div>
                <div class="agentbox">
                    <span v-for="agent in item.agentsArray">{{ agent }}</span>
                </div>
            </div>
            <div class="nameCard nameCard-defult"  v-if="nameCards.length < 6" v-for="(item,index) in 6-nameCards.length">
                <div class="city">{{city[index]}}</div>
                <img src="@/assets/images/jinonUS.png" alt="" class="defultimg">
            </div>
        </div>
    </div>
</template>

<script>
import { reqgServiceList } from '@/api'
export default {
    name: "NameCard",
    components: {},
    props: ['type'],
    data() {
        return {
            nameCard: [],
            city:['广州','东莞','北京','上海','天津','重庆']
        };
    },
    methods: {
        async getServiceList() {
            try {
                let req = await reqgServiceList({ type: this.type })
                // console.log(req)
                this.nameCard = req.data
            } catch (e) {
                console.error(e)
            }
        }
    },
    computed: {
        nameCards() {
            return this.nameCard.map(item => {
                return {
                    // 通过展开运算符 ... 保留原对象的所有其他属性
                    ...item,
                    // 同时新增一个属性就是转换后的数组
                    agentsArray: item.agents.split(';')
                }
            })
        }
    },
    mounted() {
        this.getServiceList()
    },
}
</script>
<style lang='scss' scoped>
.cardbox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.nameCard {
    width: calc((100% - 40px) / 2);
    padding: 40px 40px 50px 40px;
    display: flex;
    margin-right: 40px;
    margin-top: 60px;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.3);
}

.nameCard:nth-child(2n) {
    margin-right: 0;
}

.mobile {
    font-weight: bold;
}

.line {
    margin-left: 20px;
    margin-right: 20px;
    width: 1px;
    height: 80%;
    background-color: rgb(221, 220, 220);
}

.logobox {
    width: 100px;
    margin-bottom: 20px;

    img {
        width: 100%;
    }
}

.slash {
    color: darkgray;
}

.cnamebox {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}

.codebox {
    width: 100px;
    border: 1px solid darkgray;
    border-radius: 4px;
    overflow: hidden;

    img {
        width: 100%;

    }
}

.namebox {
    margin-bottom: 40px;
}

.name {
    font-size: 20px;
    font-weight: bold;
}

.cardinfo {
    margin-bottom: 8px;

    span {
        color: darkgray;
    }

    .iconfont {
        margin-right: 8px;
        color: $theme_color1 !important;
        font-weight: bold;
    }
}

.address {
    line-height: 1.2;
}

.agentbox {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    border-top: 1px solid $theme_color1;

    span {
        flex-grow: 1;
        padding: 8px 16px;
        background-color: white;
        color: $theme_color1;
        text-align: center;
    }

}
.agentbox span:nth-child(2n) {
    color: white;
    background-color: $theme_color1;
}
.defultimg{
    width: 100%;
    opacity: 0.2;
}
.nameCard-defult{
    display: flex;
    flex-direction: column;
    padding: 50px 30px;
}
.city{
    font-family: JinBuTi;
    font-weight: bold;
    font-size: 32px;
    color:$theme_color1 ;
}
</style>