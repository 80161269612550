
//引入路由配置模块
import about from './modules/about'
import software from './modules/software'
import article from './modules/article'
import ServiceProvider from './modules/ServiceProvider'
import AnnualFee from './modules/AnnualFee'
import Api from './modules/Api'
import plugins from './modules/plugins'
export default [
    {
        path: '/',
        redirect: '/index',
        meta: { title: '首页' },
    },
    {
        name: 'home',
        path: '/index',
        component: () => import('@/pages/Index'),
        meta: { title: '首页' },
    },
    {
        name: 'about',
        path: '/about',
        component: () => import('@/pages/About'),
        meta: { title: '关于我们' },
        children: [...about]
    },
    {
        name: 'software',
        path: '/software',
        component: () => import('@/pages/Software'),
        meta: { title: '软件功能' },
        children: [...software]
    },
    {
        name: 'article',
        path: '/article',
        component: () => import('@/pages/Aticle'),
        meta: { title: '文章动态' },
        children: [...article]
    },
    {
        name: 'ServiceProvider',
        path: '/ServiceProvider',
        component: () => import('@/pages/ServiceProvider'),
        meta: { title: '服务商' },
        children: [...ServiceProvider]
    },
    {
        name: 'AnnualFee',
        path: '/AnnualFee',
        component: () => import('@/pages/AnnualFee'),
        meta: { title: '年费监控' },
        children: [...AnnualFee]
    },
    {
        name: 'Api',
        path: '/Api',
        component: () => import('@/pages/Api'),
        meta: { title: 'Api接口' },
        children: [...Api]
    },
    {
        name: 'Plugins',
        path: '/Plugins',
        component: () => import('@/pages/Plugins'),
        meta: { title: '插件下载' },
        children: [...plugins]
    }
]