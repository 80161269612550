export default [
    {
        name: 'Windows',
        path: 'Windows',
        component: () => import('@/pages/Plugins/Windows'),
        meta: { title: 'windows插件' }
    },
    {
        name: 'Ios',
        path: 'Ios',
        component: () => import('@/pages/Plugins/Ios'),
        meta: { title: 'ios插件' }
    }
]