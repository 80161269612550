<!--  -->
<template>
  <div class="Aside">
    <div class="codebox">
      <img src="@img/weixincode.jpg" alt="">
      <div>扫码或微信搜索“企小达”</div>
      <div>关注公众号</div>
    </div>
    <!-- 关于我们 -->
    <nav class="leftnav" v-if="activeRoute == 1">
      <ul>
        <li><router-link to="/about/company" active-class="active">简介</router-link></li>
        <li><router-link to="/about/EnterpriseDirection" active-class="active">企业方向</router-link></li>
        <li><router-link to="/about/Organizational" active-class="active">组织架构</router-link></li>
        <li><router-link to="/about/Rrecruit" active-class="active">人才招聘</router-link></li>
      </ul>
    </nav>
    <!-- 软件功能 -->
    <nav class="leftnav" v-if="activeRoute == 2">
      <ul>
        <li><router-link to="/software/CustomerManage" active-class="active">客户管理</router-link></li>
        <li><router-link to="/software/OrderManagement" active-class="active">订单管理</router-link></li>
        <li><router-link to="/software/ProjectManagement" active-class="active">项目管理</router-link></li>
        <li><router-link to="/software/DeclarationManage" active-class="active">申报管理</router-link></li>
        <li><router-link to="/software/AnnualFeeReminder" active-class="active">年费提醒</router-link></li>
        <li><router-link to="/software/More" active-class="active">更多</router-link></li>
      </ul>
    </nav>
    <!-- 年费监控 -->
    <nav class="leftnav" v-if="activeRoute == 3">
      <ul>
        <li><router-link to="/software/CustomerManage" active-class="active">客户管理</router-link></li>
        <li><router-link to="/software/OrderManagement" active-class="active">订单管理</router-link></li>
        <li><router-link to="/software/ProjectManagement" active-class="active">项目管理</router-link></li>
        <li><router-link to="/software/DeclarationManage" active-class="active">申报管理</router-link></li>
        <li><router-link to="/AnnualFee/AnnualFee" active-class="active">年费提醒</router-link></li>
        <li><router-link to="/software/More" active-class="active">更多</router-link></li>
      </ul>
    </nav>
    <!-- 文章动态 -->
    <nav class="leftnav" v-if="activeRoute == 4">
      <ul>
        <li><router-link to="/article/AllNews" active-class="active">全部</router-link></li>
        <li><router-link to="/article/IndustryTrends" active-class="active">行业动态</router-link></li>
        <li><router-link to="/article/SoftwareUpdate" active-class="active">软件更新</router-link></li>
      </ul>
    </nav>
    <!-- 更多 -->
    <nav class="leftnav" v-if="activeRoute == 5">
      <ul>
        <li><router-link to="/ServiceProvider/SouthChina" active-class="active">华南区</router-link></li>
        <li><router-link to="/ServiceProvider/NorthChina" active-class="active">华北区</router-link></li>
        <li><router-link to="/ServiceProvider/CentralChina" active-class="active">华中区</router-link></li>
        <li><router-link to="/ServiceProvider/EastChina" active-class="active">华东区</router-link> </li>
        <li><router-link to="/ServiceProvider/WesternChina" active-class="active">华西区</router-link></li>
      </ul>
    </nav>
     <!-- 更多 -->
     <nav class="leftnav" v-if="activeRoute == 6">
      <ul>
        <li><router-link to="/Api/Patent" active-class="active">专利分析数据</router-link></li>
        <li><router-link to="/Api/Trademark" active-class="active">商标检索数据</router-link></li>
      </ul>
    </nav>
      <!-- 更多 -->
      <nav class="leftnav" v-if="activeRoute == 7">
      <ul>
        <li><router-link to="/Plugins/Windows" active-class="active">windows</router-link></li>
        <li><router-link to="/Plugins/Ios" active-class="active">ios</router-link></li>
      </ul>
    </nav>

    <div class="bottom-box">
      <div class="Copyrigt">{{ copyright }}</div>
      <div class="cinfo"> 深圳市元百佳科技有限公司</div>
      <div class="address"> {{ address }}</div>
      <div class="govIcon">
        <img src="@img/govIcon.gif" alt="">
        <img src="@/assets/images/police.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Asides",
  components: {},
  data() {
    return {
    };
  },
  computed: {
    //方式一：自己直接读取
    // this.$store.state.home.copyright
    //方式二：借助mapState读取：
    ...mapState('home', ['activeRoute', 'copyright', 'address', 'owCode', 'phone', 'wxCode'])
  },
  methods: {

  },
  mounted() {

  },
}
</script>
<style lang='scss' scoped>
.codebox {
  margin-top: 60px;
  text-align: center;
  margin-bottom: 80px;
}

.codebox img {
  width: 60%;
  border: 1px solid darkgray;
  border-radius: 4px;
  margin-bottom: 10px;
}

.codebox div {
  text-align: center;
  font-size: 14px;
  margin-bottom: 6px;
}

.bottom-box {
  font-size: 14px;
  width: 80%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  text-align: center;

  .cinfo {
    margin-bottom: 6px;
  }
}

.address {
  line-height: 1.2;
}

.Copyrigt {
  margin-bottom: 14px;
}

a {
  outline: none !important;
  text-decoration: none !important;
  display: inline-block;
}

ul,
li {
  padding: 0;
  margin: 0;
}

ul:hover li a {
  background-color: white;
  color: $theme_color !important;
}

.leftnav li a {
  color: $theme_color;
  display: block;
  line-height: 36px;
  border-radius: 20px;
  height: 36px;
  margin-bottom: 8px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
}

.leftnav li:hover a {
  width: 100%;
  display: block;
  color: white !important;
  background-color: $theme_color;
}

.active {
  width: 100%;
  color: white !important;
  background-color: $theme_color;
}

.govIcon {
  margin-top: 10px;
  text-align: center;
}

.govIcon img {
  width: 24px;
  margin: 0 5px;
}
</style>