export default[
    {
        name: 'CentralChina',
        path: 'CentralChina',
        component: () => import('@/pages/ServiceProvider/CentralChina'),
        meta: { title: '华中区' }
    },
    {
        name: 'EastChina',
        path: 'EastChina',
        component: () => import('@/pages/ServiceProvider/EastChina'),
        meta: { title: '华东区' }
    },
    {
        name: 'NorthChina',
        path: 'NorthChina',
        component: () => import('@/pages/ServiceProvider/NorthChina'),
        meta: { title: '华北区' }
    },
    {
        name: 'SouthChina',
        path: 'SouthChina',
        component: () => import('@/pages/ServiceProvider/SouthChina'),
        meta: { title: '华南区' }
    },
    {
        name: 'WesternChina',
        path: 'WesternChina',
        component: () => import('@/pages/ServiceProvider/WesternChina'),
        meta: { title: '华西区' }
    }
]