<template>
  <div id="app">
    <Hearder />
    <router-view></router-view>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import Hearder from '@/components/Hearder'
export default {
  name: 'App',
  data() {
    return {
      height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    };
  },
  components: { Hearder },
  methods: {
    //借助mapActions生成对应的方法，方法中会调用dispatch去联系actions(数组写法)
    ...mapActions('home', ['getBannerList', 'getCompanyInfo'])
  },
  mounted() {
    // 让store中的actions发送请求
    this.getBannerList()
    this.getCompanyInfo()
  }
}
</script>

<style lang="scss">
html {
  @font-face {
    font-family: 'JinBuTi';
    src: url('./assets/alifont/DingTalkJinBuTi.ttf'),
  }

  & {
    background-color: #ebe9e9;
  }
}


// 屏幕小于768px
@media screen and (max-width:768px) {
  main {
    width: 300px;
    margin: 0 auto;
  }

  .codebox {
    margin-top: 35px !important;
    margin-bottom: 10px !important;
  }

  .leftnav li a {
    color: $theme_color;
    display: block;
    line-height: 36px;
    border-radius: 20px;
    height: 36px;
    margin-bottom: 0 !important;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out 0s;
  }

  .bottom-box {
    margin-bottom: 0 !important;
  }

  .el-aside {
    padding-bottom: 10px !important;
  }
}

// 大于769px 小于992px
@media screen and (min-width:769px) and (max-width:992px) {
  main {
    width: 500px;
    margin: 0 auto;
  }

  .codebox {
    margin-top: 35px !important;
    margin-bottom: 10px !important;
  }

  .leftnav li a {
    color: $theme_color;
    display: block;
    line-height: 36px;
    border-radius: 20px;
    height: 36px;
    margin-bottom: 0 !important;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out 0s;
  }

  .bottom-box {
    margin-bottom: 0 !important;
  }

  .el-aside {
    padding-bottom: 10px !important;
  }
}

// 屏幕大于993px 小于 1280px
@media screen and (min-width:993px) and (max-width:1280px) {
  main {
    width: 860px;
    margin: 0 auto;
  }

  .codebox {
    margin-top: 35px !important;
    margin-bottom: 10px !important;
  }

  .leftnav li a {
    color: $theme_color;
    display: block;
    line-height: 36px;
    border-radius: 20px;
    height: 36px;
    margin-bottom: 0 !important;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out 0s;
  }

  .bottom-box {
    margin-bottom: 0 !important;
  }

  .el-aside {
    padding-bottom: 10px !important;
  }
}

// 屏幕大于1280px 小于 1366px
@media screen and (min-width:1281px) and (max-width:1366px) {
  main {
    width: 1000px;
    margin: 0 auto;
  }

  .codebox {
    margin-top: 35px !important;
    margin-bottom: 10px !important;
  }

  .leftnav li a {
    color: $theme_color;
    display: block;
    line-height: 36px;
    border-radius: 20px;
    height: 36px;
    margin-bottom: 0 !important;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out 0s;
  }

  .bottom-box {
    margin-bottom: 0 !important;
  }

  .el-aside {
    padding-bottom: 10px !important;
  }
}

// 屏幕大于1367px小于 1440px
@media screen and (min-width:1367px) and (max-width:1440px) {
  main {
    width: 1000px;
    margin: 0 auto;
  }
}

// 屏幕大于 1441px
@media screen and (min-width:1441px) {
  main {
    width: 1200px;
    margin: 0 auto;
  }
}

main,
.main {
  margin-top: 40px;
  position: relative;
}

a {
  text-decoration: none !important;
}

.Asides {
  width: 300px;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 60px;
  background-color: white;
}

.mainbox {
  padding-top: 60px;
  width: calc(100% - 300px);
  margin-left: 300px;
  background-color: #ebe9e9;
  padding-bottom: 100px;
}
</style>
