export default[
    {
        name: 'AnnualFeeReminder',
        path: 'AnnualFeeReminder',
        component: () => import('@/pages/Software/AnnualFeeReminder'),
        meta: { title: '年费提醒-示例' }
    },
    {
        name: 'CustomerManage',
        path: 'CustomerManage',
        component: () => import('@/pages/Software/CustomerManage'),
        meta: { title: '客户管理-示例' }
    },
    {
        name: 'OrderManagement',
        path: 'OrderManagement',
        component: () => import('@/pages/Software/OrderManagement'),
        meta: { title: '订单管理-示例' }
    },
    {
        name: 'ProjectManagement',
        path: 'ProjectManagement',
        component: () => import('@/pages/Software/ProjectManagement'),
        meta: { title: '项目管理-示例' }
    },
    {
        name: 'DeclarationManage',
        path: 'DeclarationManage',
        component: () => import('@/pages/Software/DeclarationManage'),
        meta: { title: '申报管理-示例' }
    },
    {
        name: 'More',
        path: 'More',
        component: () => import('@/pages/Software/More'),
        meta: { title: '更多-示例' }
    }
]