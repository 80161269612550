<!--  -->
<template>
    <div class="Adbox">
        <div class="adbox">
            <div class="adinner">
                <div>携手行业创未来</div>
            </div>
            <div class="keyword">关键词：系统 || 客户 || 管理 || 团队 || 数字化</div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Adbox",
    components: {},
    data() {
        return {

        };
    },
    methods: {

    },
    mounted() {
    
    },
}
</script>
<style lang='scss' scoped>
.adbox {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $titlecolor;
    margin-bottom: 40px;

    .adinner {
        font-size: 16px;
        color: white;
        align-items: center;
        height: 100%;
        padding: 8px;
        padding-left: 40px;
        padding-right: 100px;
        // 0 表示从这个位置（也就是 60 像素之后，因为前面的透明占了 60 像素）开始一直到渐变结束（通常是元素的对应边界）
        background: linear-gradient(-125deg, transparent 40px, $titlecolor 0);
    }

    .keyword {
        display: flex;
        align-items: end;
        padding-right: 40px;
        font-size: 15px;
        margin-bottom: 4px;
    }
}
</style>