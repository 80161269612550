export default [
    {
        name: 'company',
        path: 'company',
        component: () => import('@/pages/About/Company'),
        meta: { title: '公司简介' }
    },
    {
        name: 'EnterpriseDirection',
        path: 'EnterpriseDirection',
        component: () => import('@/pages/About/EnterpriseDirection'),
        meta: { title: '企业方向' }
    },
    {
        name: 'Organizational',
        path: 'Organizational',
        component: () => import('@/pages/About/Organizational'),
        meta: { title: '组织架构' }
    },
    {
        name: 'Rrecruit',
        path: 'Rrecruit',
        component: () => import('@/pages/About/Rrecruit'),
        meta: { title: '人才招聘' }
    },
]